import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

import { grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[100]};
  padding-top: 56px;
  padding-bottom: 56px;

  @media ${device.tablet} {
    padding-top: 44px;
    padding-bottom: 44px;
  }

  @media ${device.desktopLG} {
    padding-top: 62px;
    padding-bottom: 62px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;
  }
`

export const Card = styled.div`
  width: 260px;
  height: 277px;

  padding: 16px;
  background: ${white};
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.desktopLG} {
    height: 294px;
  }

  @media ${device.desktopXL} {
    height: 277px;
  }
`

export const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  color: ${white};
  background: ${orange.extra};
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  border-radius: 50%;
  margin-bottom: 10px;
`
