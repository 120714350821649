import React from 'react'

import Elevator from '../../assets/credito-elevador.png'
import Car from '../../assets/credito-car.png'
import Solar from '../../assets/credito-solar.png'
import Nature from '../../assets/credito-nature.png'
import Portaria from '../../assets/credito-portaria.png'
import Paint from '@interco/icons/build-v4/orangeds/MD/paint-roller'
import Config from '@interco/icons/build-v4/orangeds/MD/config'
import Check from '@interco/icons/build-v4/orangeds/MD/check-circle'

export const data = [
  {
    icon: <img src={Elevator} />,
    title: 'Modernização de elevadores',
  },
  {
    icon: <img src={Car} />,
    title: 'Cobertura de garagem',
  },
  {
    icon: <img src={Solar} />,
    title: 'Captação de energia solar',
  },
  {
    icon: <img src={Nature} />,
    title: 'Revitalização das área de lazer e social ',
  },
  {
    icon: <img src={Portaria} />,
    title: 'Portaria virtual',
  },
  {
    icon: <Paint width={42} height={42} />,
    title: 'Pinturas e reformas',
  },
  {
    icon: <Config width={42} height={42} />,
    title: 'Reparos hidráulicos',
  },
  {
    icon: <Check width={42} height={42} />,
    title: 'E muito mais benefícios',
  },
]
