import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

import CreditoBanner from '../../assets/credito.png'
import { green, white } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 56px;
  padding-bottom: 56px;
  background: url(${CreditoBanner}) ${green[500]} no-repeat bottom right;

  @media ${device.tablet} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }

  .react-multi-carousel-track {
    @media ${device.tablet} {
      padding: 0 0 30px!important;
    }
  }
`

export const Card = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  border-radius: 9px;
  width: 166px;
  height: 203px;
  margin-top: 25px;

  img {
    width: 42px;
    height: 42px;
  }

  @media ${device.tablet} {
    margin-top: 0;
    margin-bottom: 25px;
  }
`
