import React from 'react'

import SimplifiqueMobile from '../../assets/simplifique-mobile.png'
import SimplifiqueTablet from '../../assets/simplifique-tablet.png'
import SimplifiqueDesktopLG from '../../assets/simplifique-desktopLG.png'
import SimplifiqueDesktopXL from '../../assets/simplifique-desktopXL.png'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import { Card, Icon, Section } from './style'
import { data } from './_data'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Simplifique = () => {
  const breakpoint = useBreakpoint()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name: 'Simplifique sua conta digital para condomínios',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio gratuita',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  const Image = breakpoint.up('xl')
    ? SimplifiqueDesktopXL
    : breakpoint.up('lg')
    ? SimplifiqueDesktopLG
    : SimplifiqueTablet

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-start'>
          <div className='d-none d-md-block col-md-6 col-xl-5'>
            <Card className='bg-grayscale--100 d-flex align-items-center flex-column pt-5 px-4 h-100'>
              <h3 className='font-sora fs-24 lh-30 fw-600 text-grayscale--500 mb-3 mt-4 pt-3'>
                Conta PJ
              </h3>
              <p className='text-center fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-5'>
                A agilidade e simplicidade que o{' '}
                <span className='d-lg-block'>seu condomínio precisa.</span>
              </p>
              <img
                src={Image}
                className='pl-4 mt-4'
                alt='Tela da home do aplicativo Inter Empresas em um smartphone'
              />
            </Card>
          </div>

          <div className='col-12 col-md-6 offset-xl-1'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mt-lg-4 mt-xl-0'>
              Simplifique sua conta digital para condomínios
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-4 mb-lg-5 mr-2 mr-md-n2'>
              Já imaginou encontrar todos os serviços e produtos que seu
              condomínio precisa em um único Super App?
            </p>

            {breakpoint.down('md') && (
              <div className='bg-grayscale--100 rounded-4 d-flex align-items-center flex-column pt-5 px-4 mb-4 mb-xl-5'>
                <h3 className='font-sora fs-16 lh-20 fw-600 text-grayscale--500 mb-2'>
                  Conta PJ
                </h3>
                <p className='text-center fs-14 lh-17 text-grayscale--400 mb-3'>
                  A agilidade e simplicidade que o seu negócio precisa pra
                  continuar crescendo.
                </p>
                <img
                  src={SimplifiqueMobile}
                  className='pl-4'
                  alt='Tela da home do aplicativo Inter Empresas em um smartphone'
                />
              </div>
            )}

            {breakpoint.up('xl') ? (
              <div className='row'>
                <div className='col-xl-6'>
                  {data
                    ?.slice(0, 3)
                    .map((item: typeof data[0], index: number) => (
                      <div
                        className='d-flex mb-4 pb-2 pt-xl-1 align-items-center'
                        key={index}
                      >
                        <Icon className='d-flex align-items-center justify-content-center rounded-circle bg-grayscale--100'>
                          {item.icon}
                        </Icon>
                        <p className='fw-700 fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-0 ml-4'>
                          {item.title}
                        </p>
                      </div>
                    ))}
                </div>
                <div className='col-xl-6'>
                  {data
                    ?.slice(3, 6)
                    .map((item: typeof data[0], index: number) => (
                      <div
                        className='d-flex mb-4 pb-2 pt-xl-1 align-items-center'
                        key={index}
                      >
                        <Icon className='d-flex align-items-center justify-content-center rounded-circle bg-grayscale--100'>
                          {item.icon}
                        </Icon>
                        <p className='fw-700 fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-0 ml-4'>
                          {item.title}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <>
                {data?.map((item: typeof data[0], index: number) => (
                  <div
                    className='d-flex mb-4 pb-2 align-items-center'
                    key={index}
                  >
                    <Icon className='d-flex align-items-center justify-content-center rounded-circle bg-grayscale--100'>
                      {item.icon}
                    </Icon>
                    <p className='fw-700 fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400 mb-0 ml-4'>
                      {item.title}
                    </p>
                  </div>
                ))}
              </>
            )}

            <button
              className='btn btn-orange--extra text-none mb-4 mt-md-n2 mt-lg-1 mt-xl-4'
              onClick={handleLink}
            >
              Abrir conta condomínio gratuita
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
