import React from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import { Badge, Card, Section } from './style'
import { data } from './_data'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Empresas = () => {
  const breakpoint = useBreakpoint()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_7',
      section_name: 'Como abrir uma conta para condomínios no Inter Empresas?',
      element_action: 'click button',
      element_name: 'Abrir minha conta agora',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <Section>
      <div className='container'>
        <div className='row '>
          <div className='col-12 col-lg-5 col-xl-12 mb-5'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-0'>
              Como abrir uma conta para <span className='d-md-block d-lg-inline d-xl-block'>condomínios no Inter Empresas?</span>
            </h2>
          </div>

          <div className='col-12'>
            {breakpoint.up('md') ? (
              <div className='row'>
                {data?.map((item: typeof data[0], index: number) => (
                  <div className='col-md-6 mb-4 col-lg-3' key={index}>
                    <Card>
                      <Badge>{index + 1}</Badge>
                      <p className='font-sora fw-600 fs-16 lh-20 text-grayscale--500 mb-5'>
                        {item.title}
                      </p>
                      <p className='fs-14 lh-17 text-grayscale--400 mb-0 mr-n2'>
                        {item.description}
                      </p>
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 30 }}
                md={{ items: 1, partialVisibilityGutter: 150 }}
                lg={{ items: 2, partialVisibilityGutter: 38 }}
                xl={{ items: 4, partialVisibilityGutter: 0 }}
              >
                {data?.map((item: typeof data[0], index: number) => (
                  <div key={index}>
                    <Card>
                      <Badge>{index + 1}</Badge>
                      <p className='font-sora fw-600 fs-16 lh-20 text-grayscale--500 mb-5'>
                        {item.title}
                      </p>
                      <p className='fs-14 lh-17 text-grayscale--400 mb-0 mr-n2'>
                        {item.description}
                      </p>
                    </Card>
                  </div>
                ))}
              </DefaultCarousel>
            )}
          </div>

          <div className='col-12 mt-5 d-flex justify-content-center'>
            <button className='btn btn-orange--extra text-none' onClick={handleLink}>
              Abrir minha conta agora
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
