import React from 'react'

import SeguroBanner from '../../assets/seguro.png'

import { Section } from './style'
import { data } from './_data'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Seguro = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_5',
      section_name: 'Seguro condominial completo',
      element_action: 'click button',
      element_name: 'Solicitar conta condomínio',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-5 mb-md-0'>
            <img
              src={SeguroBanner}
              alt='Um eletricista fazendo um reparo em uma tomada.'
              className='w-100'
            />
          </div>

          <div className='col-12 col-md-6 col-xl-5 offset-lg-1'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4 mb-md-3 pb-2 pb-md-0'>
              Seguro condominial completo
            </h2>

            <p className='fs-18 lh-22 fs-md-14 lh-md-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4 pb-2 pb-md-0'>
              Proteja o seu condomínio de forma simples, prática e com condições
              exclusivas. Conte com{' '}
              <span className='fw-700'>assistência 24h</span> e garanta proteção
              contra:
            </p>

            {data?.map((item: typeof data[0], index: number) => (
              <div className='d-flex mb-3 align-items-center' key={index}>
                <div>{item.icon}</div>
                <p className='fw-700 fs-16 lh-20 text-grayscale--400 mb-0 ml-3'>
                  {item.title}
                </p>
              </div>
            ))}

            <button className='btn btn-orange--extra text-none mt-4' onClick={handleLink}>
              Solicitar conta condomínio
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
