export const data = [
  {
    title: 'Faça seu cadastro',
    description:
      'Nosso cadastro é simplificado: tenha em mãos os dados de seu condomínio, do representante legal e seu contrato social para dar início ao processo',
  },
  {
    title: 'Fique de olho no e-mail',
    description:
      'Você receberá um token de acesso por e-mail e, depois, pelo número de telefone cadastrado, para seguir com a abertura de conta',
  },
  {
    title: 'Separe os documentos do seu condomínio',
    description:
      'Aqui nossa prioridade é a sua segurança: envie os documentos solicitados para análise interna e aguarde nosso contato',
  },
  {
    title: 'Acesse sua conta',
    description:
      'Com a conta aberta, agora é só aproveitar uma gestão financeira muito mais completa e econômica. Acesse o App e o Internet Banking com os dados enviados para o e-mail cadastrado.',
  },
]
