import styled, { keyframes, css } from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'

import { gray, grayscale, orange, white } from 'src/styles/colors'

export const Section = styled.section`
  background: ${grayscale[100]};
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;

    &:disabled {
      color: ${white};
    }
  }
`

export const Select = styled.div`
  background: ${white};
  border-radius: 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  height: 48px;
  position: relative;
  padding: 0 50px 0 16px;
  margin-bottom: 16px;

  select {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    color: ${grayscale[500]};
    font-size: 14px;
    font-weight: 700;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::placeholder {
      color: ${grayscale[300]};
      font-size: 14px;
      font-weight: 700;
    }
  }

  svg {
    position: absolute;
    right: 22px;
  }
`

export const Checkbox = styled.div`
  margin-bottom: 36px;

  input {
    display: contents;
  }

  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: "";
    }

    &::before {
      border: 2px solid ${gray['500']};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 2px;
      width: 20px;
    }

    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }

  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;

    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
      &::before {
        background: linear-gradient(
          7.83deg,
          ${orange.dark} 0%,
          ${orange.base} 100%
        );
        border: 1px solid ${orange.dark};
      }
      &::after {
        left: 7px;
        top: 5px;
        width: 6px;
        height: 10px;
        border: solid ${white};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media ${device.tablet} {
          top: 5px;
        }
      }
    }
  }
`

type ContainerProps = {
  isModal?: () => void;
};

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};
  z-index: 9999;
  h3 {
    font-family: "Sora";
  }

  img {
    max-width: 185px;
  }

  ${(props: ContainerProps) =>
    props.isModal &&
    css`
      min-height: 100vh;
      padding: 30px 24px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 100px;
      }
    `}

  .form--default {
    label {
      color: #6a6c72;
      margin-bottom: 4px;
    }

    .form-label-check {
      &::before {
        top: 8px;
      }
    }
    .form-input-check {
      &:checked ~ label {
        &::after {
          top: 12px;
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &.under-age {
    img {
      max-width: 204px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .open-account button {
    width: 312px;
    border: 1px solid #ffb46e;
    @media ${device.tablet} {
      width: 181px;
      height: 48px;
    }
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;
`

export const Bg = styled.div`
  background: rgba(0, 0, 0, 0.48);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
`
