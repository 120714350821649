import React from 'react'

import Pix from '@interco/icons/build-v4/orangeds/MD/pix-outline'
import Boleto from '@interco/icons/build-v4/orangeds/MD/deposit-by-boleto'
import Investments from '@interco/icons/build-v4/orangeds/MD/investments'
import Seguros from '@interco/icons/build-v4/orangeds/MD/seguros'
import Config from '@interco/icons/build-v4/orangeds/MD/config'
import Credit from '@interco/icons/build-v4/orangeds/MD/credit'
import { green } from 'src/styles/colors'

export const data = [
  {
    icon: <Pix width={24} height={24} color={green[500]} />,
    title: 'Pix gratuito e ilimitado',
  },
  {
    icon: <Boleto width={24} height={24} color={green[500]} />,
    title: 'Emissão de até 100 boletos e TEDs sem taxa por mês',
  },
  {
    icon: <Investments width={24} height={24} color={green[500]} />,
    title:
      'Investimentos, para seu fundo de reserva crescer com soluções de liquidez diária',
  },
  {
    icon: <Seguros width={24} height={24} color={green[500]} />,
    title: 'Seguros com condições exclusivas',
  },
  {
    icon: <Config width={24} height={24} color={green[500]} />,
    title: 'Integrações via API para cobranças, pagamentos e transferências',
  },
  {
    icon: <Credit width={24} height={24} color={green[500]} />,
    title: 'Soluções de crédito personalizadas',
  },
]
