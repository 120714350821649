import React, { useEffect, useState } from 'react'
import Input from 'src/components/Input'

type FormDataProps = {
  setTotal: (value: number) => void;
};

export const FormData = ({ setTotal }: FormDataProps) => {
  const [ form, setForm ] = useState({
    adminTax: 0,
    condoNumber: '0',
    condoTax: 0,
    pixNumber: '0',
    pixTax: 0,
  })

  function currencyToFloat (currencyNumber: string) {
    const result =
      currencyNumber === ''
        ? 0
        : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return result
  }

  function floatToCurrency (floatNumber: number) {
    return floatNumber.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  const changeTotalValue = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const amountSpentFormated = currencyToFloat(evt.target.value)
    setForm({ ...form, [evt.target.name]: amountSpentFormated })
  }

  useEffect(() => {
    setTotal(
      (Number(form.adminTax) +
        Number(form.condoNumber) * Number(form.condoTax) +
        Number(form.pixNumber) * Number(form.pixTax)) *
        12,
    )
  }, [ form ])

  return (
    <>
      <Input
        label='Insira a taxa de administração mensal do seu banco atual:'
        type='text'
        id='adminTax'
        name='adminTax'
        placeHolder='0,00'
        classContainer='pb-lg-4'
        value={floatToCurrency(form.adminTax)}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
          changeTotalValue(evt)
        }
      />
      <div className='row'>
        <div className='col-md-6 col-lg-12'>
          <Input
            label={
              <>
                Número de{' '}
                <label className='d-md-block d-lg-inline mb-0'>
                  condôminios
                </label>
              </>
            }
            type='text'
            id='condoNumber'
            name='condoNumber'
            placeHolder='0'
            classContainer='pb-lg-4'
            value={form.condoNumber}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, condoNumber: evt.target.value })
            }
          />
          <Input
            label='Taxa de emissão de boletos no seu banco atual:'
            type='text'
            id='condoTax'
            name='condoTax'
            placeHolder='0,00'
            classContainer='pb-lg-4'
            value={floatToCurrency(form.condoTax)}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              changeTotalValue(evt)
            }
          />
        </div>

        <div className='col-md-6 col-lg-12'>
          <Input
            label='Número médio de Pix e transferência por mês'
            type='text'
            id='pixNumber'
            name='pixNumber'
            placeHolder='0'
            classContainer='pb-lg-4'
            value={form.pixNumber}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, pixNumber: evt.target.value })
            }
          />
          <Input
            label={
              <>
                Taxa de Pix e{' '}
                <label className='d-md-block d-lg-inline mb-0'>
                  transferência por mês
                </label>
              </>
            }
            type='text'
            id='pixTax'
            name='pixTax'
            placeHolder='0,00'
            classContainer='mb-lg-4'
            value={floatToCurrency(form.pixTax)}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              changeTotalValue(evt)
            }
          />
        </div>
      </div>
    </>
  )
}
