import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  padding-top: 56px;
  padding-bottom: 56px;

  @media ${device.tablet} {
    padding-top: 64px;
    padding-bottom: 0;
  }

  @media ${device.desktopXL} {
    padding-top: 128px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }
`

export const Icon = styled.div`
  width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
`

export const Card = styled.div`
  min-height: 655px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

`
