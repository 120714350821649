import styled from 'styled-components'

import { grayscale, green, white } from 'src/styles/colors'

import Banner from '../../assets/form-banner.png'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: ${green[500]};
  padding-top: 56px;
  padding-bottom: 56px;

  @media ${device.tablet} {
    background: url(${Banner}) no-repeat ${green[500]};
    padding-top: 44px;
    padding-bottom: 44px;
  }

  @media ${device.desktopLG} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }

  img {
    @media ${device.tablet} {
      height: 287px;
    }
    
    @media ${device.desktopLG} {
      height: 360px;
    }

    @media ${device.desktopXL} {
      height: 420px;
    }
  }
`

export const Card = styled.div`
  background: ${white};
  border-radius: 16px;
  padding: 32px 24px;

  @media ${device.tablet} {
    padding: 56px 48px;
  }

  label {
    font-weight: 600;
    font-family: "Sora";
    font-size: 14px;
    line-height: 17px;
    color: ${grayscale[400]};
  }

  input {
    background: ${grayscale[100]};
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${grayscale[300]};

  @media ${device.tablet} {
    width: 352px;
  }

  @media ${device.desktopLG} {
    width: 100%;
  }
`
