import React, { useState } from 'react'

import FormImage from '../../assets/form.png'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'
import { Card, Divider, Section } from './style'
import { FormData } from './_FormData'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Form = () => {
  const breakpoint = useBreakpoint()
  const [ sendDatalayerEvent ] = useDataLayer()
  const [ total, setTotal ] = useState(0)

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_3',
      section_name: 'Tenha uma conta condomínio que te ajuda a economizar',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio gratuita',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  function floatToCurrency (floatNumber: number) {
    return floatNumber.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }
  return (
    <Section id='simulador'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-lg-6 col-xl-5 mb-4 mb-md-5 mb-lg-0'>
            {breakpoint.down('md') && (
              <h2 className='font-sora fw-600 fs-24 lh-30 text-white mb-4 mr-n2'>
                Tenha uma conta condomínio que te ajuda a economizar
              </h2>
            )}
            <div className='row'>
              <div className='col-md-6 col-lg-12'>
                <img
                  src={FormImage}
                  className='mw-100 mt-2 mt-md-0 mb-4 mb-md-0 mb-lg-4'
                  alt='imagem de um prédio. Ao fundo vemos o céu azul com algumas nuvens'
                />
              </div>
              <div className='col-md-6 col-lg-12'>
                {breakpoint.up('md') && (
                  <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-md-3 mr-n2 mt-lg-3'>
                    Tenha uma conta condomínio que te ajuda a economizar
                  </h2>
                )}
                <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-0 mb-md-5 mb-lg-4 mt-2 mt-md-0 mr-n2 mr-lg-n3'>
                  Com nossa conta 100% gratuita e digital você consegue
                  economizar de verdade na gestão do seu condomínio. Faça a
                  simulação e veja quanto você pode passar a poupar anualmente
                </p>
                {breakpoint.up('md') && (
                  <button className='btn btn-orange--extra text-none mt-md-3' onClick={handleLink}>
                    Abrir conta condomínio gratuita
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-6 col-xl-5 offset-xl-2 mt-2 mt-md-n1 mt-lg-0 mb-4 mb-md-0'>
            <Card>
              <p className='text-grayscale--500 font-sora fw-600 fs-16 lh-20 fs-md-20 lh-md-25 mb-3 mr-n2 text-md-center text-lg-left'>
                Veja o quanto pode economizar com a nossa calculadora
              </p>

              <div className='mb-4'>
                <FormData setTotal={setTotal} />
              </div>

              <p className='font-sora fw-600 fs-20 lh-25 text-grayscale--400 mb-2'>
                Total
              </p>
              <p className='font-sora fw-600 fs-24 lh-30 text-orange--extra mb-1'>
                R$ {floatToCurrency(total)}
              </p>
              <Divider />
              <p className='fs-14 lh-17 text-grayscale--400 mb-0 mt-3 mr-lg-n2'>
                Caso você tenha mais de 100 condôminos esse valor pode mudar
              </p>
            </Card>
          </div>

          {breakpoint.down('md') && (
            <div className='col-12 mt-2'>
              <button className='btn btn-orange--extra text-none' onClick={handleLink}>
                Abrir conta condomínio gratuita
              </button>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}
