import React from 'react'

import HeroBanner from '../../assets/hero.png'

import { useBreakpoint } from 'src/hooks/window/useBreakpoint'

import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const Hero = () => {
  const breakpoint = useBreakpoint()
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_1',
      section_name: 'Conta Condomínio 100% gratuita e digital? Simples.',
      element_action: 'click button',
      element_name: 'Abrir conta condomínio gratuita',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <Section>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='d-none d-md-block col-md-6 col-xl-7'>
            <img
              src={HeroBanner}
              alt='No lado esquerdo vemos a imagem de um condomínio. Já no lado direito vemos a síndica sorridente enquanto faz uma ligação através do seu smartphone.'
              className='mw-100 mb-4 mb-md-0 pr-lg-5'
            />
          </div>

          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-4 mb-md-3'>
              <span className='d-md-block'>Conta Condomínio</span> 100% gratuita
              e digital? Simples.
            </h1>
            {breakpoint.down('md') && (
              <img
                src={HeroBanner}
                alt='No lado esquerdo vemos a imagem de um condomínio. Já no lado direito vemos a síndica sorridente enquanto faz uma ligação através do seu smartphone.'
                className='mw-100 mb-4'
              />
            )}
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4 pb-2 pb-md-3'>
              Só o Inter Empresas te garante uma conta PJ para condomínios
              realmente completa e sem burocracias
            </p>
            <button
              className='btn btn-orange--extra text-none'
              onClick={handleLink}
            >
              Abrir conta condomínio gratuita
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
