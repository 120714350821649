import React from 'react'
import { UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'

import Input from 'src/components/Input'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Checkbox, Select } from './style'
import { FormProps } from './_index'

type FormDataProps = {
  methods: UseFormMethods<FormProps>;
  accept: boolean;
  setAccept: (accept: boolean) => void;
};

export const FormData = ({ methods, accept, setAccept }: FormDataProps) => {
  const { errors, register, setValue }: UseFormMethods<FormProps> = methods

  return (
    <>
      <div className='row w-100'>
        <div className='col-lg-4'>
          <Input
            label='Nome do Condomínio '
            type='text'
            id='company'
            name='company'
            placeHolder='Insira o nome do condomínio'
            classContainer='pb-lg-4'
            ref={register({
              required: 'Campo obrigatório',
            })}
            error={errors?.company}
          />
        </div>
        <div className='col-lg-4'>
          <Input
            label='CNPJ'
            type='text'
            id='cnpj'
            name='cnpj'
            placeHolder='000.000.000/0000-00'
            classContainer='pb-lg-4'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue('cnpj', Masks.MaskCNPJ(event.currentTarget.value))
            }
            ref={register({
              required: 'CNPJ obrigatório',
              validate: {
                isCnpj: (value: string) =>
                  Validations.cnpj(value) || 'CNPJ Inválido',
              },
            })}
            error={errors?.cnpj}
          />
        </div>
        <div className='col-lg-4'>
          <Input
            label='E-mail'
            type='text'
            id='email'
            name='email'
            placeHolder='Insira seu e-mail'
            classContainer='pb-lg-4'
            ref={register({
              required: 'E-mail obrigatório',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'E-mail inválido',
              },
            })}
            error={errors?.email}
          />
        </div>
        <div className='col-lg-4'>
          <Input
            label='Nome'
            type='text'
            id='name'
            name='name'
            placeHolder='Insira seu nome de acesso'
            classContainer='pb-lg-4'
            ref={register({
              required: 'Campo obrigatório',
            })}
            error={errors?.name}
          />
        </div>
        <div className='col-lg-4'>
          <Input
            label='Telefone'
            type='text'
            id='mobilePhone'
            name='mobilePhone'
            placeHolder='(00) 00000-0000'
            classContainer='pb-lg-4'
            ref={register({
              required: 'Telefone obrigatório',
              pattern: {
                value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                message: 'Telefone inválido',
              },
            })}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(
                'mobilePhone',
                Masks.MaskPHONE(event.currentTarget.value),
            )
            }
            error={errors?.mobilePhone}
          />
        </div>

        <div className='col-lg-4'>
          <label
            className='fs-14 lh-16 text-grayscale--400 fw-400 mb-1'
            htmlFor='description'
          >
            Relação com condomínio
          </label>
          <Select>
            <select id='description' name='description' ref={register}>
              <option value='Síndico / Administrador'>
                Síndico / Administrador
              </option>
              <option value='Morador'>Morador</option>
            </select>
            <OrangeIcon size='MD' color='#ff7a00' icon='chevron-down' />
          </Select>
        </div>
      </div>

      <div className='col-lg-8 mx-auto'>
        <Checkbox onClick={() => setAccept(!accept)}>
          <input
            type='checkbox'
            className='form-input-check'
            checked={accept}
          />
          <label className='form-label-check fs-12 lh-15 pt-lg-1'>
            Autorizo o Inter a tratar meus dados pessoais para envio de
            comunicações sobre seus produtos e serviços e também estou de acordo
            com a{' '}
            <a
              className='fw-700'
              title='Acessar Política de Privacidade do Inter'
              href='https://inter.co/politica-de-privacidade/privacidade/'
              target='_blank'
              rel='noreferrer'
            >
              Política de Privacidade.
            </a>
          </label>
        </Checkbox>
      </div>
    </>
  )
}
