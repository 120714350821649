import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 64px;
  padding-bottom: 64px;

  @media ${device.desktopXL} {
    padding-top: 156px;
    padding-bottom: 156px;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }
`
