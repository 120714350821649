import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import {
  Credito,
  Dicas,
  Empresas,
  FaqSection,
  Form,
  Hero,
  Seguro,
  Simplifique,
} from './sections/_index'

const ContaCondominio = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <Simplifique />
      <Form />
      <Dicas />
      <Seguro />
      <Credito />
      <Empresas />
      <FaqSection />
    </Layout>
  )
}

export default ContaCondominio
