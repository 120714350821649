import React, { ChangeEventHandler } from 'react'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import { FieldValues } from 'react-hook-form'
import { InputContainer } from './style'

type ValidationType = {
  required?: string;
  pattern?: {};
  validate?: {};
};

type InputType = {
  onChange?: ChangeEventHandler;
  error?: FieldValues;
  label: string | React.ReactNode;
  registerValidator?: Function;
  validation?: ValidationType;
  placeHolder: string;
  type: string;
  id: string;
  name: string;
  value?: string;
  className: string;
  classContainer?: string;
  maxLength: number;
  ref?: any;
};

const Input = React.forwardRef<HTMLInputElement, Partial<InputType>>(
  (
    {
      onChange,
      label,
      error,
      registerValidator,
      validation,
      placeHolder,
      type,
      id,
      name,
      value,
      className,
      maxLength,
      classContainer,
      ...props
    }: Partial<InputType>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <InputContainer>
        <div
          className={`col-12 pb-3 d-flex flex-column px-0 ${classContainer ||
            ''} ${error && 'input-error'}`}
        >
          <label
            htmlFor={id}
            className='fs-14 lh-18 d-block text-grayscale--400'
          >
            {label}
          </label>
          <input
            className={className}
            // ref={registerValidator ? registerValidator(validation) : undefined}
            name={name}
            id={id}
            type={type}
            maxLength={maxLength}
            placeholder={placeHolder}
            value={value}
            onChange={onChange ? onChange : () => false}
            ref={registerValidator ? registerValidator(validation) : ref}
            {...props}
          />
          {error && <p className='fs-12 text-right mb-0'>{error.message}</p>}
        </div>
      </InputContainer>
    )
  },
)

Input.defaultProps = {
  type: 'text',
  label: 'Nome',
  name: 'nome',
  placeHolder: 'Digite seu nome',
  className: 'w-100',
  maxLength: 100,
  validation: {
    required: 'Por favor, digite seu nome completo',
    validate: {
      isName: (value: string) =>
        Validations.name(value) || 'Por favor, digite seu nome completo',
    },
  },
}

export default Input
