import React from 'react'

import ComputerIcon from '@inter/icons/orangeds/MD/computer-call'
import ResearchIcon from '@inter/icons/orangeds/MD/inter-research'

import { Card, Section } from './style'
import { data } from './_data'
import { white } from 'src/styles/colors'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const CardComponent = ({ index }: { index: number }) => (
  <Card key={data[index].title}>
    <div className='d-flex'>{data[index].icon}</div>
    <p className='fs-16 lh-19 text-grayscale--4index0 mb-0 mr-n2'>
      {data[index].title}
    </p>
  </Card>
)

export const Credito = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = () => {
    sendDatalayerEvent({
      section: 'dobra_6',
      section_name: 'Crédito para condomínios',
      element_action: 'click button',
      element_name: 'Solicitar conta condomínio',
      redirect_url:
        'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
    })
    window.open(
      'https://conta-digital-pj.bancointer.com.br/login?&gaid=GA1.3.1078703007.1678797168',
      '_blank',
    )
  }

  return (
    <Section>
      <div className='container'>
        <div className='row '>
          <div className='col-12 col-md-6 col-xl-4'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white mb-3 mr-md-2'>
              Crédito para <span className='d-md-block'>condomínios</span>
            </h2>
            <p className='fs-16 lh-19 fs-md-14 lh-md-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white mb-4 mr-md-2'>
              Com o Inter Empresas, as{' '}
              <span className='fw-700'>
                obras e os projetos do seu condomínio
              </span>{' '}
              não precisam ser motivo de dor de cabeça: aproveite nossas{' '}
              <span className='fw-700'>
                condições especiais de crédito
              </span>{' '}
              para realizar os sonhos de seus moradores com muito mais
              simplicidade
            </p>

            <div className='mb-xl-4'>
              <div className='d-flex align-items-center mb-3'>
                <div className='d-flex'>
                  <ComputerIcon width={24} height={24} color={white} />
                </div>
                <p className='font-sora fw-700 fs-16 lh-20 fs-md-18 lh-md-23 mb-0 text-white ml-3'>
                  Taxa de <span style={{ color: '#FFB46E' }}>2,79%</span> a.m.
                </p>
              </div>
              <div className='d-flex align-items-start'>
                <div className='d-flex'>
                  <ResearchIcon width={24} height={24} color={white} />
                </div>
                <p className='font-sora fw-700 fs-16 lh-20 fs-md-18 lh-md-23 mb-0 text-white ml-3'>
                  Prazo de até{' '}
                  <span style={{ color: '#FFB46E' }}>60 meses</span> para <span className='d-lg-block'>pagar</span>
                </p>
              </div>
            </div>

            <button className='d-none d-md-block btn btn-orange--extra text-none mt-3 mt-xl-5' onClick={handleLink}>
              Solicitar conta condomínio
            </button>
          </div>

          <div className='col-12 col-md-6 col-xl-8'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 120 }}
              md={{ items: 1, partialVisibilityGutter: 150 }}
              lg={{ items: 2, partialVisibilityGutter: 38 }}
              xl={{ items: 4, partialVisibilityGutter: 0 }}
            >
              <div>
                <CardComponent index={0} />
                <CardComponent index={4} />
              </div>
              <div>
                <CardComponent index={1} />
                <CardComponent index={5} />
              </div>
              <div>
                <CardComponent index={2} />
                <CardComponent index={6} />
              </div>
              <div>
                <CardComponent index={3} />
                <CardComponent index={7} />
              </div>

            </DefaultCarousel>
          </div>

          <div className='col-12 d-md-none'>
            <button className='btn btn-orange--extra text-none mt-4' onClick={handleLink}>
              Solicitar conta condomínio
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}
