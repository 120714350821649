import React, { useState } from 'react'
import axios from 'axios'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import { Bg, CloseButton, Container, Section } from './style'
import { FormData } from './_FormData'
import { UseFormMethods, useForm } from 'react-hook-form'
import * as URLS from 'config/api/Urls'

import SuccessImage from '../../assets/success.png'
import ErroImage from '../../assets/erro.png'

export type FormProps = {
  company: string;
  cnpj: string;
  email: string;
  name: string;
  mobilePhone: string;
  description: string;
  accept: boolean;
};

export const Dicas = () => {
  const methods: UseFormMethods<FormProps> = useForm<FormProps>()
  const [ status, setStatus ] = useState<boolean | undefined>()
  const [ erro, setErro ] = useState<boolean | undefined>()
  const [ accept, setAccept ] = useState(false)

  const onSubmit = async (data: FormProps) => {
    const formData = [
      {
        cnpj: data.cnpj.replace(/[^\w\s]/gi, '').replace(' ', ''),
        celular: data.mobilePhone.replace(/[^\w\s]/gi, '').replace(' ', ''),
        nomeCondominio: data.company,
        relacaoCondominio: data.description,
        sobrenomeSindico: data.name,
        email: data.email,
        TermoAceite: accept,
      },
    ]

    try {
      await axios.post(
        'https://crm-api.bancointer.com.br/institucional/contato/v2/canais/lead-contapj-condominio',
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      setStatus(true)
      methods.reset()
      setAccept(false)
    } catch (e) {
      setErro(true)
      methods.reset()
    }
  }

  const closeButton = (
    <CloseButton
      className='border-0 bg-transparent pt-4'
      onClick={() => setStatus(undefined)}
    >
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      {(status || erro) && (
        <>
          <Bg />
          <Container
            isModal={() => {
              setStatus(undefined)
              setErro(false)
            }}
            className='d-flex align-items-center sent'
          >
            {closeButton}
            <div className='col-12 px-0 px-md-5 d-flex flex-column justify-content-between h-100'>
              <div />
              <div>
                <div className='col-12 mb-4 mx-auto text-center'>
                  {status && <img src={SuccessImage} alt='Sucesso' />}
                  {erro && <img src={ErroImage} alt='Erro' />}
                </div>
                <p className='fs-14 lh-17 fs-md-20 lh-md-25 text-grayscale--400 text-center text-md-left fw-600 mr-md-5'>
                  {erro &&
                    'Houve um erro por aqui, por isso não foi possível continuar. Tente novamente mais tarde.'}
                  {status &&
                    'Prontinho! Recebemos os seus dados e enviaremos para o seu e-mail nossas melhores soluções.'}
                </p>
              </div>
              <button
                className='btn btn-orange--extra text-white fw-600 text-none fs-14'
                onClick={() => {
                  setStatus(undefined)
                  setErro(false)
                }}
              >
                Entendi
              </button>
            </div>
          </Container>
        </>
      )}
      <Section>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='font-sora fs-20 lh-25 fs-md-24 lh-md-30 text-grayscale--500 fw-600 mb-4 text-md-center'>
                Receba dicas de como economizar e melhorar{' '}
                <span className='d-md-block d-lg-inline'>
                  a gestão do condomínio
                </span>
              </h2>
            </div>
            <div className='col-12 mt-3 d-md-flex flex-md-column justify-content-md-center align-items-lg-center'>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormData
                  methods={methods}
                  accept={accept}
                  setAccept={setAccept}
                />
                <div className='col-lg-8 mx-auto d-flex align-items-center justify-content-center'>
                  <button
                    className='btn btn-orange--extra text-none mx-md-auto'
                    disabled={!accept}
                  >
                    Cadastrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}
